import { UserApiClient } from "@torc-robotics/mm-user-client";
import { Auth } from "aws-amplify";

/* istanbul ignore next */
const UsersAPI = new UserApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_USER_API_URL,
  TOKEN: async () =>
    await Auth.currentSession().then((res) => res.getIdToken().getJwtToken()),
}).v1;

export default UsersAPI;
