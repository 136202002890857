import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UsersAPI from "../../../framework/api/UsersAPI";
import { toast } from "react-toastify";

function DeleteUserDialog({ show, onClose, user }) {
  const queryClient = useQueryClient();

  const deleteUserAPI = useMutation({
    mutationKey: ["users", user?.email],
    mutationFn: () =>
      UsersAPI.deleteUserV1UsersUserEmailDelete({ userEmail: user.email }),
    onSuccess: () => {
      toast.success(`Successfully deleted ${user.email}.`, {
        toastId: "success-delete",
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      /* istanbul ignore next */
      toast.error("An error occurred when attempting to delete the user.");
    },
  });

  const onConfirm = async () => {
    deleteUserAPI.mutateAsync();
    onClose();
  };

  return (
    <Dialog open={show} onClose={onClose} data-testid="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure that you want to delete ${user.email}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-delete-user" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-testid="confirm-delete-user"
          variant="contained"
          color="error"
          onClick={onConfirm}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserDialog;
