import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Button,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useGetAllUsersQuery from "../../../hooks/query/users/useGetUsersQuery";
import useGetAllUserRolesQuery from "../../../hooks/query/users/useGetAllUserRolesQuery";
import SearchBar from "./UserSearch";
import UserRow from "./UserRow";
import CreateUser from "./CreateUser";
import { useState } from "react";
import "./scss/UserSearch.scss";
import "./scss/CreateUser.scss";

const UserList = () => {
  const { data: allUsers, isLoading: usersLoading } = useGetAllUsersQuery();
  const { data: allRoles, isLoading: loadingRoles } = useGetAllUserRolesQuery();
  const [showCreateForm, setShowCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filterData = (query, data) => {
    return data?.filter((user) =>
      user.email.toLowerCase().includes(query?.toLowerCase())
    );
  };

  const dataFiltered = filterData(searchQuery, allUsers);

  return !usersLoading && !loadingRoles ? (
    <>
      <div className="create__user">
        <Button
          onClick={() => {
            setShowCreate(!showCreateForm);
          }}
          variant="contained"
          data-testid="create-user-button"
          startIcon={<PersonAddIcon fontSize="small" />}
        >
          Create User
        </Button>
      </div>
      <div className="user__form" id="user-form">
        {showCreateForm ? (
          <CreateUser showSelf={setShowCreate} allRoles={allRoles} />
        ) : null}
      </div>
      <SearchBar
        data-testid="search-user-bar"
        setSearchQuery={setSearchQuery}
      />
      <TableContainer sx={{ height: "fill" }}>
        <Table sx={{ minWidth: 400 }}>
          <TableBody>
            {dataFiltered.map((user) => (
              <UserRow key={user.email} user={user} allRoles={allRoles} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <CircularProgress data-testid="loading-user-list" />
  );
};

export default UserList;
