import _ from "lodash";
import moment from "moment-timezone";

/**
 * @param {string} date The date to format, in ISO 8601 format, epoch time also supported.
 * @param {string} timezone - Optional parameter The specified timezone to default to.
 * @param {string} format - Optional parameter - The specified moment format.
 * @param {string} invalidValueDefault - Optional Localized value to show when date is invalid - default is null.
 * @returns The formatted time.
 */
export function getFormattedDate(
  date,
  timezone = moment.tz.guess(),
  format = "MM/DD HH:mm",
  invalidValueDefault = "---"
) {
  let d;

  if (typeof date === "string") {
    d = moment(date).tz(timezone);
  } else {
    d = moment.tz(date, date < 9999999999 ? "X" : "x", timezone); // convert to Moment object
  }
  // let d = moment.tz(date, timezone)
  if (date && d.isValid()) {
    return d.format(format);
  } else {
    return invalidValueDefault;
  }
}

/**
 * Returns a string of roles given a users roles
 *
 * @param {array} roles - Roles from the db
 * @param {array} userRolesArray - a users array of roles
 * @return {string} Returns a string of roles
 */
export function getRolesString(roles, userRolesArray) {
  if (userRolesArray && userRolesArray.length > 0) {
    let rolesStr = [];
    userRolesArray.forEach((uuid) => {
      let role = _.find(roles, ["uuid", uuid]);
      if (role) {
        rolesStr.push(role.name);
      }
    });
    return rolesStr.join(", ");
  } else {
    return "No Roles";
  }
}

/**
 * Returns the number of users with a given role
 *
 * @param {string} roleUUID - Role UUID
 * @param {array} userArray - Users form the db
 * @return {int} Returns a number of roles
 */
export function getUserAmountForRole(roleUUID, userArray) {
  let userCount = 0;
  userArray.forEach((user) => {
    if (_.includes(user.roles, roleUUID)) {
      userCount++;
    }
  });
  return userCount;
}

/**
 * Convert "email" to "lowercase" and check UNASSIGNED
 *
 * @param {string} email
 */
export function caseConverter(email) {
  return _.toLower(_.trim(email)) == "unassigned"
    ? "UNASSIGNED"
    : _.toLower(_.trim(email));
}
