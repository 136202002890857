import { Button } from "@mui/material";

const DeleteConfirmation = ({
  deleteFunction,
  itemName,
  closeModule,
  deleteButtonText = "Delete",
}) => {
  return (
    <div className="confirm-delete">
      <div className="confirm-delete-content">
        <h1>Confirm Delete</h1>
        <p>
          Delete <b>{itemName}</b>
        </p>
        <div className="confirm-delete-btn-group">
          <Button
            data-testid="cancel-delete-button"
            data-dd-action-name="cancel-delete-button"
            className="cancel"
            onClick={() => closeModule()}
          >
            Cancel
          </Button>
          <Button
            data-testid="confirm-delete-button"
            data-dd-action-name="confirm-delete-button"
            variant="danger"
            className="delete"
            onClick={() => {
              deleteFunction();
              closeModule();
            }}
          >
            {deleteButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
