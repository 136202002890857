import VehiclesApi from "../../../framework/api/VehiclesAPI";
import { useQuery } from "@tanstack/react-query";

const useGetV2VehiclesQuery = () => {
  const v2VehiclesQuery = useQuery({
    queryKey: ["vehicles"],
    queryFn: async () => await VehiclesApi.getVehiclesV2({ count: 1000 }),
    retry: 1,
  });

  return v2VehiclesQuery;
};

export default useGetV2VehiclesQuery;
