import UsersAPI from "../../../framework/api/UsersAPI";
import { useQuery } from "@tanstack/react-query";

const useGetAllUsersQuery = () => {
  const getAllUsersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => await UsersAPI.getUsersV1UsersGet(),
    retry: 1,
  });

  return getAllUsersQuery;
};

export default useGetAllUsersQuery;
