import React from "react";
import AdminPage from "./components/admin/AdminPage";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { UserProvider } from "@torc-robotics/mcli-mfui-auth";
import awsExports from "./aws-config";

Amplify.configure(awsExports);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchInterval: 30000 },
  },
});

export default function Root() {
  return (
    <UserProvider>
      <ToastContainer
        className="toast-top"
        theme="colored"
        position="top-center"
      />
      <QueryClientProvider client={queryClient}>
        <Box
          data-testid="admin-root-box"
          id="admin-root-box"
          sx={{ height: "100vh" }}
        >
          <AdminPage />
        </Box>
      </QueryClientProvider>
    </UserProvider>
  );
}
