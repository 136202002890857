import UsersAPI from "../../../framework/api/UsersAPI";
import { useQuery } from "@tanstack/react-query";

const useGetAllUserRolesQuery = () => {
  const getAllRolesQuery = useQuery({
    queryKey: ["user-roles"],
    queryFn: async () => await UsersAPI.getRolesV1AdminRolesGet(),
    retry: 1,
  });

  return getAllRolesQuery;
};

export default useGetAllUserRolesQuery;
