/**
 * Api.jsx is a utility class that wraps around AWS Amplify and
 * incases all api functionality
 */
import { API, Auth } from "aws-amplify";

export const getJwtToken = async () => {
  // currentSession checks if token is expired and refreshes if needed
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

/**
 * API GET
 * @param  {string} apiName - The name of the api
 * @param  {string} path - The path for the api call
 * @param  {obj} body - The body of the api call
 * @return {obj} Response
 */
export const APIGet = async (apiName, path, body = {}) => {
  body = {
    ...body,
    headers: { Authorization: `Bearer ${await getJwtToken()}` },
  };

  const response = await API.get(apiName, path, body)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
      return {
        error: error,
      };
    });
  return response;
};

/**
 * API POST
 * @param  {string} apiName - The name of the api
 * @param  {string} path - The path for the api call
 * @param  {obj} body - The body of the api call
 * @return {obj} Response
 */
export const APIPost = async (apiName, path, body = {}) => {
  body = {
    ...body,
    headers: { Authorization: `Bearer ${await getJwtToken()}` },
  };

  const response = await API.post(apiName, path, body)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
      return {
        error: error,
      };
    });
  return response;
};

/**
 * API PUT
 * @param  {string} apiName - The name of the api
 * @param  {string} path - The path for the api call
 * @param  {obj} body - The body of the api call
 * @return {obj} Response
 */
export const APIPut = async (apiName, path, body = {}) => {
  body = {
    ...body,
    headers: { Authorization: `Bearer ${await getJwtToken()}` },
  };

  const response = await API.put(apiName, path, body)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
      return {
        error: error,
      };
    });
  return response;
};

/**
 * API DELETE
 * @param  {string} apiName - The name of the api
 * @param  {string} path - The path for the api call
 * @param  {obj} body - The body of the api call
 * @return {obj} Response
 */
export const APIDelete = async (apiName, path, body = {}) => {
  body = {
    ...body,
    headers: { Authorization: `Bearer ${await getJwtToken()}` },
  };

  const response = await API.del(apiName, path, body)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
      return {
        error: error,
      };
    });
  return response;
};
