/* eslint-disable no-console */
import { getUserAmountForRole } from "../../framework/utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { UserContext } from "@torc-robotics/mcli-mfui-auth";

const RoleList = () => {
  const {
    roleData,
    userData,
    allUsersData,
    permissionData,
    deleteRole,
    addNewRole,
    updateRole,
  } = useContext(UserContext);
  const [deleteModule, setDeleteModule] = useState(null);
  const [createRole, setCreateRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editRole, setEditRole] = useState([]);

  const handleDelete = (role) => {
    setDeleteModule(
      <DeleteConfirmation
        deleteFunction={() => deleteRole(role.uuid)}
        itemName={`Role ${role.name}`}
        closeModule={() => closeDeleteValidation()}
        deleteButtonText="Delete Role"
      />
    );
  };

  const closeDeleteValidation = () => {
    setDeleteModule(null);
  };

  /* istanbul ignore next */
  /* deprioritized */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setIsLoading(true);
      const roleBody = {
        headers: {},
        body: {
          name: form.roleName.value,
          description: form.roleDescription.value,
          attributes: editRole.attributes,
        },
      };

      addNewRole(roleBody).then(function () {
        setIsLoading(false);
        setCreateRole(false);
        setEditRole([]);
        setValidated(false);
      });
    }
    setValidated(true);
  };

  const handleEdit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity() === false) {
      setIsLoading(true);
      const roleBody = {
        headers: {},
        body: editRole,
      };
      console.debug("updating role --> /role");
      updateRole(roleBody, editRole.uuid).then(function () {
        setIsLoading(false);
        setEditRole([]);
        setValidated(false);
      });
    }
    setValidated(true);
  };

  const populatePerms = (permission) => {
    let newEditRole = { ...editRole };
    let newPerms = _.get(newEditRole, "attributes", []);
    if (permission.currentTarget.checked) {
      newPerms.push(permission.currentTarget.id);
    } else {
      _.remove(newPerms, function (object) {
        return object === permission.currentTarget.id;
      });
    }
    newEditRole.attributes = newPerms;
    setEditRole(newEditRole);
  };

  const permissionCheckboxes = useMemo(() => {
    let checkedRoles = _.get(editRole, "attributes", []);
    return permissionData.map((perm) => {
      return (
        <FormControlLabel
          key={perm.permission_key}
          control={
            <Checkbox
              data-testid={perm.permission_key}
              checked={_.includes(checkedRoles, perm.permission_key)}
              id={perm.permission_key}
              onChange={(event) => populatePerms(event)}
              className="classic"
            />
          }
          label={perm.display_name}
        />
      );
    });
  }, [permissionData, editRole]);

  const editForm = useMemo(() => {
    return (
      <form onSubmit={handleEdit}>
        <Typography variant="h6" gutterBottom component="div">
          Name: {_.get(editRole, "name", "")}
        </Typography>
        <Typography variant="h6" gutterBottom component="div">
          Description: {_.get(editRole, "description", "")}
        </Typography>
        <div className="user-mngmt-checks">
          <InputLabel>Permission List</InputLabel>
          {permissionCheckboxes}
        </div>

        <div className="user-mngmt-btn-group">
          <Button
            variant="outlined"
            data-testid="cancel-edit-role-button"
            onClick={() => {
              setEditRole([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="submit-role-button"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </div>
      </form>
    );
  }, [editRole]);

  const roles = useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 400 }}>
          <TableBody data-testid="roles-table">
            {roleData.map((role) => (
              <>
                <TableRow key={role.uuid}>
                  <TableCell>
                    <p className="user-mngmt-name">{role.name}</p>
                    <p>Description: {role.description}</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      Users: {getUserAmountForRole(role.uuid, allUsersData)}
                    </p>
                    <p>Permissions: {role.attributes.length}</p>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      data-testid={`${role.name}-edit-button`}
                      color="primary"
                      onClick={() => {
                        setEditRole(_.cloneDeep(role));
                        setCreateRole(false);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      size="small"
                      data-testid={`${role.name}-delete-button`}
                      color="error"
                      onClick={() => handleDelete(role)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <Collapse
                    in={editRole && editRole.uuid == role.uuid}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div className="user-mngmt-form">
                      <p>Edit Role</p>
                      <div key={`editform${editRole.name}`}>{[editForm]}</div>
                    </div>
                  </Collapse>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [JSON.stringify(roleData), editRole]);

  /* istanbul ignore next */
  /* deprioritized */
  const createForm = useMemo(() => {
    return (
      <form key="createForm" onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="roleName">Role Name</InputLabel>
          <Input
            id="roleName"
            type="text"
            required
            placeholder="Enter role name..."
          />
          <FormHelperText>
            Suggestion: Make role names unique for easier role management.
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="roleDescription">Role Description</InputLabel>
          <Input
            id="roleDescription"
            type="text"
            required
            placeholder="Enter role description..."
          />
        </FormControl>

        <div className="user-mngmt-checks">
          <InputLabel>Permission List</InputLabel>
          {permissionCheckboxes}
        </div>

        <div className="user-mngmt-btn-group">
          <Button
            variant="outlined"
            onClick={() => {
              setCreateRole(false);
              setEditRole([]);
              setValidated(false);
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="submit-role-button-create"
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : "Create"}
          </Button>
        </div>
      </form>
    );
  });
  /* istanbul ignore next */
  /* deprioritized */
  return (
    <div>
      {deleteModule}
      <div className="user-mngmt-title">
        <h1>Role Management</h1>
        <div>
          {userData.preferences.beta ? (
            <Button
              onClick={() => {
                setCreateRole(true);
                setEditRole([]);
              }}
              data-testid="create-role-button"
            >
              Create Role
            </Button>
          ) : null}
        </div>
      </div>
      {createRole ? (
        <div className="user-mngmt-form">
          <p>Create Role</p>
          {createForm}
        </div>
      ) : null}
      {roles}
    </div>
  );
};

export default RoleList;
