import {
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { useState } from "react";
import { remove } from "lodash";
import UsersAPI from "../../../framework/api/UsersAPI";
import "./scss/CreateUser.scss";
import { toast } from "react-toastify";
import { useQueryClient, useMutation } from "@tanstack/react-query";

const CreateUser = ({ showSelf, allRoles }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [email, setUserEmail] = useState("");
  const [invalidEmail, validateEmail] = useState(true);
  const queryClient = useQueryClient();

  const createUserAPI = useMutation({
    mutationKey: ["users", email, "create"],
    mutationFn: () =>
      UsersAPI.putUserV1UsersUserEmailPut({
        userEmail: email,
        requestBody: {
          email: email,
          roles: selectedRoles,
        },
      }),
    onSuccess: () => {
      toast.success(`Successfully created ${email}.`, {
        toastId: "success-create",
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      /* istanbul ignore next */
      toast.error("An error occurred when attempting to create the user.");
    },
  });

  const setRoles = (role) => {
    let newRoles = selectedRoles ? selectedRoles : [];
    if (role?.currentTarget.checked) {
      newRoles.push(role.currentTarget.id);
    } else {
      remove(newRoles, function (object) {
        return object === role.currentTarget.id;
      });
    }
    setSelectedRoles(newRoles);
  };

  const onCancel = () => {
    showSelf(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!invalidEmail) {
      createUserAPI.mutateAsync();
      onCancel();
    }
  };

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      validateEmail(true);
    } else {
      validateEmail(false);
    }
  };

  const mapRoles = () => {
    return allRoles.map((role) => {
      return (
        <FormControlLabel
          key={role.uuid}
          className="row__role"
          control={
            <Checkbox
              data-testid={`${role.name}-check`}
              id={role.uuid}
              size="small"
              onChange={(event) => setRoles(event)}
            />
          }
          label={role.name}
          slotProps={{ typography: { variant: "overline" } }}
        />
      );
    });
  };

  return (
    <>
      <div>
        <form autoComplete="off">
          <TextField
            helperText={
              invalidEmail
                ? "Please enter a valid email in the format of: {email}@{domain}"
                : ""
            }
            required
            label="User Email"
            className="user__email__input"
            id="userEmail"
            onChange={handleEmailChange}
            error={invalidEmail}
            inputProps={{
              "aria-label": "email",
              "data-testid": "user-email-input",
            }}
          />
          <Typography variant="h6" sx={{ paddingTop: "2%" }}>
            User Roles:
          </Typography>
          {mapRoles()}
          <div id="create__footer" className="create__user__footer">
            <Button
              onClick={onSubmit}
              variant="contained"
              color="success"
              data-testid="submit-create-user-button"
            >
              Submit
            </Button>
            <Button
              onClick={onCancel}
              variant="contained"
              color="error"
              data-testid="cancel-user-button"
              sx={{ marginRight: "0.5%" }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateUser;
